import styled from 'styled-components';

import dynamic from 'next/dynamic';

const MobileSection = dynamic(() => import('modules/home/v2/ComparisonSection/mobile'));
const DesktopSection = dynamic(() => import('modules/home/v2/ComparisonSection/desktop'));

const Mobile = styled.div`
  @media (min-width: 1170px) {
    display: none !important;
  }
  overflow-x: hidden;
`;

const Desktop = styled.div`
  @media (max-width: 1170px) {
    display: none !important;
  }
`;

function CreateAccountSection(): JSX.Element {
  return (
    <>
      <Mobile>
        <MobileSection />
      </Mobile>
      <Desktop>
        <DesktopSection />
      </Desktop>
    </>
  );
}

export default CreateAccountSection;
